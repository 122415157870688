import React from "react"
import styled from "styled-components"
import Img from 'gatsby-image'
import { Link } from "gatsby"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import BreakpointUp from "../../components/Media/BreakpointUp"


const Article = styled.div`
	position: relative;
	border:1px solid #ddd;
	padding:0 15px 15px;
	margin-bottom:30px;
	background-color:#fff;
	${BreakpointUp.xl`				
		padding:0 50px 50px;
	`}
	& > a{
		position:relative;
		display:block;
	}
	& .gatsby-image-wrapper{
		position: relative;
		width: 100%;
	}
	& h3 {
		font-size: 20px;
		line-height: 30px;
		color:#020304;
		margin-top: 30px;
		margin-bottom:15px;
	}

	& p {		
		text-align: left;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
`

const CreatTime = styled.div`
	position: absolute;
	bottom:0;
	left:0;
	width: fit-content;
	background-color:#020304;
	padding: 6px 10px;
	font-size: 12px;
	line-height: 20px;
	color: white;
`

const ReadMore = styled.div`
	> a{
		display:inline-block;
		color:#020304;
		font-weight:700;
		position:relative;		
		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			display: block;
			height: 2px;
			transition: -webkit-transform .4s cubic-bezier(.77,0,.175,1);        
			transition: transform .4s cubic-bezier(.77,0,.175,1);
			transition: transform .4s cubic-bezier(.77,0,.175,1),-webkit-transform .4s cubic-bezier(.77,0,.175,1);        
			transform: translateZ(0) scaleX(1);
			background: #020304;
			transform-origin: right center;    
			transform: translateZ(0) scaleX(0);        
		}
		& svg{
			fill:#020304;
			display: inline-block;
			vertical-align: middle;
			transition: transform 0.3s ease-out;
		}
		&:hover {
			color:#020304;
			&:after {
				transform-origin: left center;        
				transform: translateZ(0) scaleX(1);
			}  
			& svg{
				fill:#020304;
				transform: translateX(4px);
			}
		}
	}

`

const ArticleCard = ({ data }) => {
	return(			
		<Article>
			<Link to={data.url}>
				<Img fluid={data.thumbnail.fluid} />
				<CreatTime>{data.createdAt}</CreatTime>
			</Link>
			<h3>{data.title}</h3>
			<p>{data.content.childMarkdownRemark.excerpt}</p>
			<ReadMore>
				<Link to={data.url}>Read Full Story <ArrowIcon /></Link>
			</ReadMore>
		</Article>
	
	)
}

export default ArticleCard