import React, { Component } from 'react'
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link } from "gatsby"
import Img from 'gatsby-image'
import ArrowIcon from "../../components/Icons/ArrowIcon"
import LeftArrowIcon from "../../components/Icons/LeftArrowIcon"
import BreakpointUp from "../../components/Media/BreakpointUp"

const SlickItem = styled.div`
  padding: 25px;
  display:block !important;
  outline:none;
  max-width:100%;
  img{
    max-width:100%;
    min-width:100%;
  }
`

const Card = styled.div`
	margin-bottom:30px;
  display:flex;
  flex-wrap:wrap;
  flex-direction:column-reverse;
	align-items:center;
	background: #fff;	
  position:relative;
  border:1px solid #ddd;
  padding:0 0 60px;
  ${BreakpointUp.lg`
    padding:60px 0;
    flex-direction:row; 
  `}
`
const CardTitle = styled(Link)`
  margin: 0 0 20px;
  color: #020304;
  font-weight: 700;
  display: block;
  font-family: 'IBM Plex Serif', serif;
  font-size: 28px;
  line-height: 38px;
  ${BreakpointUp.xl`
    font-size: 34px;
    line-height: 40px;
  `}

`
const CardDescripton = styled.p` 
  font-size: 18px;
  line-height: 32px;
  display: block;
  font-style:italic;

`
const CardFigure = styled.div`
	width: 100%;
  position:relative;
  margin-bottom:30px;
  height: 300px;
	& .gatsby-image-wrapper{
		height:100%;
  }
  ${BreakpointUp.lg`
    width: 40%; 
    margin-bottom:0;
  `}
`
const CardContent = styled.div`
  position:relative;
  z-index:1;
  padding: 0 15px;  
  ${BreakpointUp.lg`
    width: 60%;    
  `}
  ${BreakpointUp.xl`
    padding: 0 70px;
  `}
`
const CardOption = styled.ul`
  margin:0 -15px 10px -15px;	
  list-style: none;    
  padding: 0;
`
const CardOptionItem = styled.li`
	display:inline-block;
	margin:0 15px;
	color:#666666;
	font-weight:500;	
	position:relative;
	&:before{
		display: inline-block;
		content: '';
		width: 6px;
		height: 6px;
		background-color: #676B79;
		border-radius: 50%;
		position: absolute;
		left: -20px;
		top: 50%;
		transform: translateY(-50%);
	}
	&:first-child{
    color:#000;
    font-weight: 700;
		&:before{
			display:none;
		}
	}
`
const ReadMore = styled.div`
	> a{
		color:#020304;
		font-weight:700;
		& svg{
			fill:#020304;
			display: inline-block;
			vertical-align: middle;
		}
		&:hover {
			color:#020304;
			& svg{
				fill:#020304;
			}
		}
	}
`

const RightArrow = styled.div`
  display: none;
  position: absolute;
  top: 450px;
  right: 0px;
  width: 50px;
  height: 50px;
  padding: 12px 5px;
  border-radius: 50px;
  border: 1px solid #ddd; 
  background: white;
  text-align: center;
  z-index: 10;
  & :hover {
    cursor: pointer;
    & svg {
      transition: all .3s ease;
      transform: translateX(5px);
    }
  }
  @media(max-width: 767px) {
    display: block;
  }
`
const LeftArrow = styled.div`
  display: none;
  position: absolute;
  top: 450px;
  left: 0px;
  width: 50px;
  height: 50px;
  padding: 12px 5px;
  border-radius: 50px;
  background: white;
  border: 1px solid #ddd; 
  text-align: center;
  z-index: 10;
  & :hover {
    cursor: pointer;
    & svg {
      transition: all .3s ease-in-out;
      transform: translateX(-5px);
    }
  }
  @media(max-width: 767px) {
    display: block;
  }
`
function CircleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, display: "flex"}}
      onClick={onClick}
    ><ArrowIcon/></button>
  );
}

function CirclePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, display: "flex"}}
      onClick={onClick}
    ><ArrowIcon/></button>
  );
}

export default class RelatedProductCarousel extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const { data } = this.props
    const settings = {
      centerMode: true,
      centerPadding: '94px',
      slidesToShow: 1,
      infinite: true,      
      slidesToScroll: 1,
      speed: 300,
      arrows: false,
      responsive: [     
        {
          breakpoint: 767,
          settings: {
            centerPadding: '0',
          }
        }
      ]      
    };
    
    return (
      <div>     
        <Slider {...settings} ref={c => (this.slider = c)}>
          {
            data.map((item, i) => {
              return(
                <SlickItem>
                  <Card>
                    <CardContent>
                      <CardOption>
                        <CardOptionItem>Featured Article</CardOptionItem>
                        <CardOptionItem>{item.node.createdAt}</CardOptionItem>
                      </CardOption>  
                      <CardTitle to={item.node.url}>{item.node.title}</CardTitle>
                      <CardDescripton>{item.node.content.childMarkdownRemark.excerpt}</CardDescripton>
                      <ReadMore>
                        <Link to={item.node.url}>Read Full Story <ArrowIcon /></Link>
                      </ReadMore>
                    </CardContent>
                    <CardFigure>
                      <Img fluid={item.node.thumbnail.fluid} />                
                    </CardFigure>
                  </Card>    
                </SlickItem>    
              )
            })
          }
        </Slider>
        <LeftArrow onClick={this.previous}>
           <LeftArrowIcon fill="#020304" />
        </LeftArrow>
        <RightArrow onClick={this.next}>
          <ArrowIcon fill="#020304" />
        </RightArrow>
      </div>
    );
  }
}
