import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Section, SectionTitle, BreadCrumb} from "../components/section"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticleCard from "../components/ArticleList"
import ArticleCarousel from "../components/ArticleCarousel"
import BreakpointUp from "../components/Media/BreakpointUp"


const ArticleGrid = styled.div`
    display: grid;
    grid-template-rows: auto;
    grid-column-gap: 30px;
    grid-template-columns: repeat(1, 1fr);
    ${BreakpointUp.sm`
      grid-template-columns: repeat(2, 1fr);
    `}
    ${BreakpointUp.lg`
      grid-template-columns: repeat(3, 1fr);
    `}
  
`
const ArticleHeroSection = styled.div`
    
 
`
const ListTag = styled.div`
    margin:0 -5px 30px -5px;
    text-align:center;  
`
const TagItem = styled.div`
  background: ${props=>props.bg};
  color: ${props=>props.color};
  display: inline-block;
  padding: 5px 15px;
  border: 1px solid #ddd;
  border-radius: 100px;
  background: #fff;
  color: #020304;
  font-size: 14px;
  line-height: 18px;
  margin: 10px 5px;
  font-weight: 700;
  cursor:pointer;
	&:hover {
    background-color:#020304;
    color:#fff;
	}
`
class ArticlePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'all',
      seletedArticles: this.props.data.allContentfulArticle.edges
    }
    this.selectTab = this.selectTab.bind(this)
  }
  selectTab(data) {
    const  articles  = this.props.data.allContentfulArticle.edges
    if (data==="all") {
      this.setState({
        seletedArticles: this.props.data.allContentfulArticle.edges   
      })
    } else if (data === "general") {
      let tempArticles = []
      articles.forEach(function(item){
        if(!item.node.category) {
          tempArticles.push(item)
        }
      })
      this.setState({
        seletedArticles: tempArticles
      })
    } else {
      let tempArticles = []
      articles.forEach(function(item){
        if(item.node.category && item.node.category.name===data) {
          tempArticles.push(item)
        }
      })
      this.setState({
        seletedArticles: tempArticles
      })
    }
  }
  render() {
    const { data, location } = this.props
    const pageData = data.contentfulArticleListPage
    const articles = data.allContentfulArticle
    const categories = data.allContentfulProductCategory
    let featuredArticleList = []
    articles.edges.forEach(function(item){
      if(item.node.featuredArticle) {
        featuredArticleList.push(item)
      }
    })
    const { seletedArticles } = this.state
    return(
      <Layout location={location}>
        <SEO title={pageData.metaTitle} description={pageData.metaDescription} />
        <Section pt="140px" pb="30px" xpb="0">
          <ArticleHeroSection>
            <div className="container">
              <BreadCrumb><Link to='/'>Home</Link>/<span>Blog</span></BreadCrumb>
            </div>
            <ArticleCarousel data={featuredArticleList} />
          </ArticleHeroSection>
        </Section>  
        <Section pt="15px" pb="90px" bg="#fff">
          <div className="container">      
            <SectionTitle maxWidth="550px">Explore Our All Articles</SectionTitle>     
            <ListTag>
              <TagItem onClick={()=>this.selectTab('all')} color="#030504" bg="#fff">All Articles</TagItem>
              <TagItem onClick={()=>this.selectTab('general')} color="#030504" bg="#fff">General</TagItem>
              {
                categories.edges.map((item, i) => {
                  return(
                    <TagItem onClick={()=>this.selectTab(item.node.name)} color="#030504" bg="#fff">{item.node.name}</TagItem>
                  )
                })
              }
            </ListTag> 
            <ArticleGrid>
              {
                seletedArticles.map((item, i) => {
                  return(
                    <ArticleCard data={item.node} />
                  )
                })
              }
            </ArticleGrid>  
          </div>
        </Section>  
      </Layout>
    )
  }
}

export default ArticlePage

export const pageQuery = graphql`
  query ArticlePageQuery {
    contentfulArticleListPage {
      metaTitle
      metaDescription
      secondSectionTitle
    }
    allContentfulArticle(sort: {fields: createdAt, order: DESC}) {
      edges {
        node {
          thumbnail {
            fluid {
              base64
              src
              aspectRatio
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          featuredArticle
          createdAt(fromNow: true)
          content {
            childMarkdownRemark {
              excerpt
            }
          }
          url
          category {
            name
          }
        }
      }
    }
    allContentfulProductCategory {
      edges {
        node {
          name
        }
      }
    }
  }
`